<template>
  <div>
    <Header></Header>
    <BGImg></BGImg>
    <div style="max-width: 900px;margin: 10px auto 0 auto">

    </div>
    <div style="max-width: 1200px;margin: auto;border-bottom: 1px solid rgb(247, 248, 249); "></div>
    <div class="main">
      <div class="menu-left">
        <TitleLeft v-for="(name,index) in titleNames"
                   :content="name"
                   :active="active===index"
                   @click.native="change(index)"></TitleLeft>
      </div>
      <div class="doclist">
        <div v-if="body" style="max-width: 900px;width: 100%;margin: auto;text-align:justify;">
          <TinyMCE v-model="body" :edit_bar_show="false"></TinyMCE>
        </div>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import RoutePath from "@/components/DocList/RoutePath"
import TitleTop from "@/components/DocList/TitleTop";
import TitleLeft from "@/components/DocList/TitleLeft";
import DocItem from "@/components/DocList/docItem";
import Header from "@/components/Header";
import BGImg from "@/components/BGImg";
import Footer from "@/components/Footer";
import {mapMutations} from "vuex";
import TinyMCE from "@/components/TinyMCE";
export default {
  name: "Brief",
  components: {TinyMCE, Footer, BGImg, Header, DocItem, TitleLeft, TitleTop, RoutePath},
  data(){
    return{
      body:null,
      section:{
        sectionId: 9,
        title: "新闻",
        banner: null,
      },
      titleNames:[
        {title:"公司简介",docId:1},
        {title:"经营范围",docId:1},
      ],
      doclist:{},
      currentPage:1,
      pageSize:15,
      active:this.$route.query.categoryid?this.$route.query.categoryid:0,
    }
  },
  computed:{

  },
  methods:{
    ...mapMutations([
      'setDocListPaths'
    ]),
    // change(index){
    //   this.active = index
    //   this.body = this.doclist[index].body_content;
    // },
    change(index){
      this.active = index
      this.loadDocList(this.titleNames[index].categoryId)
      this.paths[2] = {name:this.titleNames[index].title,path: null}
      this.setDocListPaths(this.paths)
      console.log(this.paths)
    },
    changePage(){
      console.log(this.pageSize,this.currentPage)
    },
    handleSizeChange(val) {
      console.log(val,typeof (val))
      this.pageSize = val;
      this.changePage();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.changePage();
    },
    loadDocList(categoryId){
      this.axios.get('/api/document/user/article/list/'+categoryId)
          .then(res=>{
            this.doclist = res.data
            let results = this.doclist.results
            console.log(res.data)
            if(results.length > 0){
              this.axios.get('/api/document/user/article/view/'+results[0].articleId+'/')
                .then(res=>{
                  this.doclist[0] = res.data
                  this.body = this.doclist[0].body_content;
                })
            }else{
              this.body = null;
            }
          })
    },
    link(url){
      window.location.href = url;
    }
  },
  mounted() {
    // this.axios.get('/api/document/user/article/view/'+36+'/')
    //     .then(res=>{
    //       this.doclist[0] = res.data
    //       this.body = this.doclist[0].body_content;
    //     })
    //
    // this.axios.get('/api/document/user/article/view/'+37+'/')
    //     .then(res=>{
    //       this.doclist[1] = res.data
    //     })
    //获取section name 修改paths
    this.axios.get('/api/document/user/section/restrieve/'+this.section.sectionId)
        .then(res=>{
          this.section = res.data
          this.paths = [
            {name:"首页",path:'/'},
            {name:res.data.title,path:'/doclist?id='+this.section.sectionId},
          ]
          // 获取category列表
          this.axios.get('/api/document/user/category/list/'+this.section.sectionId)
              .then(res=>{
                this.titleNames = res.data
                this.doclist = []
                if(this.titleNames.length > 0){
                  this.loadDocList(this.titleNames[this.active].categoryId)
                  this.paths.push(
                      {name:this.titleNames[this.active].title,path: null}
                  )
                }
                this.setDocListPaths(this.paths)
              })
        })
  }
}
</script>

<style scoped>
.main{
  max-width: 900px;
  margin: auto;
  display: flex;
}
.menu-left{
  width: 100%;
  padding-top: 30px;
  max-width: 220px;
  text-align: left;
}
.doclist{
  max-width: 640px;
  margin: 10px 20px;
  width: 100%;
}
.page{
  margin: 20px auto;
}
</style>